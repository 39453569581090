.wrapper {
  border-radius: 6px;
  width: 100%;
  padding: 20px 16px;
  border: 1px solid #ccc;

  .title {
    font-size: 13px;
    opacity: 0.5;
  }
  a {
    display: inline-block;
    margin-top: 8px;
    font-weight: 500;
    font-size: 16px;
    text-decoration: underline;
  }
  .amount {
    margin-top: 8px;
    font-weight: 500;
    font-size: 16px;
  }
}
