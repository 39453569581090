.header {
  margin-bottom: 15px;
  font-size: 20px;
}

.body {
  padding: 16px;
  background-color: white;
  border-radius: 8px;
}

.wallets-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
}

.wallet-button {
  display: flex;
  align-items: center;
  gap: 16px;

  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px;
  background-color: #f8f8f8;

  &:hover {
    background-color: #eeeeee;
  }

  h3 {
    font-weight: normal;
    font-size: 14px;
  }

  img {
    width: 40px;
    height: 40px;
  }
}
