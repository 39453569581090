.wrapper {
  width: 70%;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 100%;
  }

  .maintTitle {
    padding-top: 20px;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;

    @media (max-width: 900px) {
      padding-top: 0px;
      font-size: 28px;
      line-height: 32px;
    }
  }

  .additionalTitle {
    padding-top: 80px;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 28px;
      line-height: 32px;
    }
  }

  .subSection {
    display: flex;
    gap: 48px;
    justify-content: center;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  .width50 {
    width: 50%;

    @media (max-width: 900px) {
      width: 100%;
    }
  }

  .section {
    margin-top: 48px;

    @media (max-width: 900px) {
      margin-top: 36px;
    }

    .textStrong {
      font-weight: bold;
    }

    .title {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 16px;
      line-height: 28px;

      @media (max-width: 900px) {
        font-size: 18px;
        margin-bottom: 12px;
      }
    }

    .teamTitle {
      font-size: 20px;
      font-weight: 600;
      text-align: justify;
      margin-bottom: 16px;
      line-height: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .photoWrapper {
        display: flex;
        width: 337px;
        height: 337px;
        margin-bottom: 8px;

        @media (max-width: 900px) {
          width: 100%;
          height: auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 6px;
        }
      }

      .jobTitle {
        font-size: 13px;
      }

      @media (max-width: 900px) {
        font-size: 18px;
        margin-bottom: 12px;
      }
    }

    .text {
      font-size: 16px;
      line-height: 28px;
      text-align: justify;

      @media (max-width: 900px) {
        font-size: 16px;
        line-height: 24px;
      }

      ul {
        list-style-type: disc;

        @media (max-width: 900px) {
          padding-left: 12px;
        }
        li {
          margin-top: 8px;
        }
      }
    }
  }
}
