.wrapper {
  height: 372px;
  min-height: 372px;
  max-height: 372px;
  display: flex;
  width: 100%;
  gap: 8px;

  @media (max-width: 690px) {
    height: 240px;
    min-height: 240px;
    max-height: 240px;
  }

  .mobileOnly {
    display: flex !important;
    width: 100% !important;
  }

  .imgWrapper {
    width: 50%;
    height: 100%;
    display: flex;
    gap: 8px;

    @media (max-width: 690px) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .horizWrapper {
      display: flex;
      flex-direction: column;
      height: 50%;
    }
  }

  .rightSection {
    display: flex;
    width: 50%;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 690px) {
      display: none;
    }

    .subSection {
      height: 182px;
      display: flex;
      gap: 8px;

      .imgWrapperFull {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 8px;

        @media (max-width: 690px) {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

// .imageSection {
//   display: flex;
//   max-width: 1120px;
//   max-height: 380px;

//   @media (max-width: 900px) {
//     height: 360px;
//   }

//   .largeImageContainer {
//     flex: 0 0 calc(50% - 4px);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 4px;
//     max-height: 380px;
//     aspect-ratio: 1;

//     @media (max-width: 600px) {
//       flex: auto;
//     }

//     .largeImage {
//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//     }
//   }

//   .smallImagesContainer {
//     max-height: 240px;
//     flex: 0 0 calc(50% - 4px);
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     grid-template-rows: repeat(2, 1fr);
//     gap: 8px;
//     padding: 4px;

//     @media (max-width: 600px) {
//       display: none;
//     }

//     .smallImageContainer {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       max-height: 240px;

//       img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//       }
//     }
//   }
// }
