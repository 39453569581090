.wrapper {
  .tabs {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 8px;

    @media (max-width: 600px) {
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
      height: 38px;
      display: none;
    }

    .tab {
      flex-grow: 1;
      position: relative;
      border: none;
      background: none;
      cursor: pointer;
      padding: 10px 16px;
      opacity: 0.3;
      font-size: 16px;
      font-weight: 500;

      @media (max-width: 600px) {
        padding: 8px 12px;
      }

      &.active {
        opacity: 1;

        &:after {
          content: "";
          position: absolute;
          bottom: -2px;
          left: 16px;
          right: 16px;
          border-bottom: 3px solid black;

          @media (max-width: 600px) {
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .mobileDropdown {
    display: none; // Скрыт по умолчанию
    cursor: pointer;
    position: relative;

    // Стили для мобильного выпадающего списка
    @media (max-width: 600px) {
      display: block; // Показывать на мобильных устройствах
    }
  }

  .mobileDropdownHeader {
    padding: 12px 48px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    cursor: pointer;
    border-radius: 6px;

    @media (max-width: 1130px) {
      padding: 16px 20px;
      font-size: 12px;
    }
  }

  .mobileDropdownList {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #faf5f5;
    border: 1px solid #dddddd;
    border-top: none;
    z-index: 9;

    .mobileDropdownItem {
      padding: 12px 48px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      cursor: pointer;
      opacity: 0.4;

      &:hover,
      &.active {
        opacity: 1;
        background-color: #ffffff;
      }

      @media (max-width: 1130px) {
        padding: 12px 20px;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 600px) {
  .wrapper .mobileDropdownList {
    display: block; // Показывать при активации
  }
}