.wrapper {
  width: 100%;
  background-color: #ffffff;
  min-height: 40px;
  box-shadow: 0px 4px 8px 0px #dddddd;
  padding: 0 40px;
  position: fixed;
  z-index: 9;
  top: 0;

  @media (min-width: 957px) {
    display: none;
  }

  @media (max-width: 690px) {
    padding: 0 20px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    .logo {
      width: 112px;
      // height: 56px;

      img {
        width: 100%;
      }
    }

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 4px;

      .iconWrapper {
        height: 60px;
        width: 56px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .textWrapper {
        height: 40px;
        width: 100px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .menuButton {
      width: 32px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .menuItems {
    border-top: 1px solid #ddd;
    color: #222;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    padding: 20px 0;

    .logoutBtn {
      margin-top: 12px;
      width: 100%;
      text-align: center;
      border-top: 1px solid #22222239;
      padding: 16px 0;
      cursor: pointer;
    }

    a {
      padding: 12px 0;
      font-weight: 500;
      font-size: 14px;
      opacity: 0.4;
      color: #222222;

      &:hover {
        opacity: 1;
      }

      &.active {
        opacity: 1;
      }
    }

    // animation: slideDown 0.3s ease-out;
  }

  .mobileSection {
    display: flex;
    flex-direction: column;
  }

  .signIn {
    padding: 12px 0;
    font-weight: 500;
    font-size: 14px;
    opacity: 0.4;
    color: #222222;

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }
  }

  .signUp {
    padding: 12px 0;
    font-weight: 500;
    font-size: 14px;
    opacity: 0.4;
    color: #222222;

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }
}
