.progressBarContainer {
  margin-top: 18px;
  padding: 0 12px;
  color: #ff385c;
  font-size: 11px;
  font-weight: bold;

  .progressBarBackground {
    background-color: #faf2f2;
    width: 100%;
    height: 6px;
    border-radius: 5px;

    .progressBarFill {
      background-color: #ff385c;
      height: 100%;
      border-radius: 5px;
    }
  }

  .progressBarText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 6px;

    .percentage {
      border: 1.5px solid #ff385c;
      border-radius: 10px;
      padding: 2px 8px;
    }

    .total {
      opacity: 0.4;
    }
  }
}
