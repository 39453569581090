.wrapper {
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  padding: 28px 16px;
  align-items: center;
  &:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 28px 0;
  }
  .leftSection {
    display: flex;
    align-items: center;
    gap: 72px;
    @media (max-width: 900px) {
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      width: 100%;
    }
    .sellerInfo {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 240px;
      @media (max-width: 900px) {
        width: 100%;
      }
      .sellerImage {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4b4b4b;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #fff;
        font-size: 18px;
      }
      .sellerName {
        .fullName {
          font-size: 16px;
          font-weight: 500;
        }
        .orderType {
          margin-top: 4px;
          font-size: 12px;
          padding: 6px 10px;
          border: 1px solid #ddd;
          border-radius: 6px;
          color: #222;
        }
      }
    }
    .priceInfo {
      font-size: 20px;
      font-weight: 500;
      width: 160px;
      text-align: right;
      @media (max-width: 900px) {
        text-align: left;
      }
      span {
        font-size: 14px;
        opacity: 0.7;
      }
    }
    .limitsInfo {
      .available {
        display: flex;
        align-items: center;
        .label {
          width: 60px;
          font-size: 12px;
          font-weight: 500;
          opacity: 0.6;
        }
        .value {
          font-size: 15px;
          font-weight: 600;

          span {
            opacity: 0.4;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }

      .limits {
        margin-top: 12px;
        display: flex;
        align-items: center;
        .label {
          width: 60px;
          font-size: 12px;
          font-weight: 500;
          opacity: 0.6;
        }
        .value {
          font-size: 15px;
          font-weight: 600;

          span {
            opacity: 0.4;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .rightSection {
    @media (max-width: 900px) {
      width: 100%;
    }
    .actionBtn {
      padding: 16px 32px;
      border-radius: 4px;
      background: linear-gradient(0deg, rgb(255, 56, 92) 35%, rgb(255, 122, 146) 100%);
      text-align: center;

      @media (max-width: 900px) {
        margin-top: 20px;
        width: 100%;
      }

      .btnLink {
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
    .disabled {
      padding: 16px 32px;
      border-radius: 4px;
      background: linear-gradient(0deg, rgb(135, 135, 135) 35%, rgb(135, 135, 135) 100%);
      text-align: center;
      pointer-events: none;
      cursor: not-allowed;

      @media (max-width: 900px) {
        margin-top: 20px;
        width: 100%;
      }
      
      .disBtnLink {
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}
