.container {
    width: max-content;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    & button {
        border: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border-radius: 50%;
        aspect-ratio: 1;
        background: #FF385C;
        stroke: white;
    }

    & button:disabled {
        background: #FFE7EC;
        stroke: #344054;
    }

    & svg {
        width: 20px;
        height: 20px;
    }
}

.pagination {
    gap: 2px !important;
    & span {
        width: 40px !important;
        height: 14px !important;
        background-color: #FF385C !important;
        border-radius: unset !important;
        margin: 0 1px !important;
        cursor: pointer;
    }

    & span:first-of-type {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }

    & span:last-of-type {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
}

