.wrapper {
  display: flex;
  padding: 16px;
  border-radius: 8px;
  color: #222;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #e9e9e952;
  }

  @media (max-width: 900px) {
    padding: 0;
    padding-bottom: 20px;
    justify-content: left;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
  }

  .leftSection {
    display: flex;
    gap: 40px;

    @media (max-width: 900px) {
      flex-direction: column;
      gap: 12px;
    }

    .info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      @media (max-width: 900px) {
        margin-top: 32px;
        justify-content: left;
      }

      .iconWrapper {
        background-color: #dfe4e870;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        @media (max-width: 900px) {
          width: 32px;
          height: 32px;
        }

        img {
          width: 16px;
        }
      }

      .txtData {
        .type {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 6px;
        }

        .date {
          min-width: 140px;
          font-size: 12px;
          font-weight: 500;
          opacity: 0.4;
        }
      }
    }

    .assetsExchnage {
      display: flex;
      gap: 32px;

      @media (max-width: 900px) {
        gap: 8px;
      }

      .iconExchange {
        width: 16px;
      }
    }

    .primaryAsset {
      display: flex;
      gap: 8px;
      align-items: center;

      .iconWrapper {
        width: 32px;
        height: 32px;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .txtData {
        .amount {
          font-size: 16px;
          margin-bottom: 6px;
          font-feature-settings: "zero", "ss01";

          @media (max-width: 900px) {
            font-size: 14px;
            margin-bottom: 2px;
          }
        }

        .dnrAmount {
          font-feature-settings: "zero", "ss01";
          font-size: 13px;
          opacity: 0.4;
        }
      }
    }
  }

  .rightSection {
    display: flex;
    gap: 32px;
    align-items: center;

    .transactionLink {
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      text-decoration: underline;
      font-family: monospace;

      @media (max-width: 900px) {
        display: none;
      }
    }

    .statusWrapper {
      align-items: center;
      padding: 4px 8px;
      background-color: antiquewhite;
      border-radius: 6px;
      display: flex;
      text-decoration: none;
      font-weight: 500;
      opacity: 0.7;
      font-size: 12px;

      .statusImg {
        width: 14px;
      }

      @media (max-width: 900px) {
        margin-top: 8px;
        padding: 4px 12px;
        font-size: 14px;
      }

      .statusTxt {
        margin-left: 8px;
      }
    }
  }

  .mobileSection {
    display: none;
    @media (max-width: 900px) {
      display: flex;
    }

    .mobLink {
      margin-top: 16px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;
      font-family: monospace;
    }
  }
}
