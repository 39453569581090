.wrapper {
  width: 100%;
  background-color: #ffffff;
  height: 80px;
  box-shadow: 0px 4px 8px 0px #dddddd;
  position: fixed;
  z-index: 9;
  top: 0;

  @media (max-width: 957px) {
    display: none;
  }

  .content {
    max-width: 1760px;
    height: 100%;
    padding: 0 149px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1366px) {
      padding: 0 60px;
    }

    .rightSection {
      display: flex;
      align-items: center;
      gap: 32px;

      .logoWrapper {
        display: flex;
        align-items: center;
        gap: 6px;

        .iconWrapper {
          height: 72px;
          width: 72px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 80%;
            height: 80%;
          }
        }

        .textWrapper {
          height: 40px;
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .logo {
        width: 120px;
        // height: 56px;

        img {
          width: 100%;
        }
      }

      .navItems {
        display: flex;
        gap: 20px;

        a {
          font-weight: 500;
          font-size: 14px;
          opacity: 0.4;
          color: #222222;

          &:hover {
            opacity: 1;
          }

          &.active {
            opacity: 1;
          }
        }
      }
    }

    .leftSection {
      display: flex;
      gap: 12px;
      align-items: center;

      .signIn {
        padding: 12px 24px;
        border-radius: 4px;
        color: #222;
        background: rgb(255, 255, 255);
        font-size: 16px;

        &:hover {
          background: rgb(247, 245, 245);
        }
      }

      .signUp {
        padding: 12px 24px;
        border-radius: 4px;
        color: #fff;
        background: rgb(255, 56, 92);
        background: linear-gradient(0deg, rgba(255, 56, 92, 1) 35%, rgba(255, 122, 146, 1) 100%);
        font-size: 16px;

        &:hover {
          font-weight: 500;
        }
      }

      .btnsWrapper {
        display: flex;
        gap: 4px;

        .btn {
          border-radius: 8px;
          cursor: pointer;

          &:hover {
            background-color: #f8f8f8;
          }
        }
      }

      .profile {
        img {
          width: 44px;
          height: 44px;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
  }
}
