.wrapper {
  flex: 1;
  width: 100%;
  min-height: calc(100vh - 121px);
  .content {
    max-width: 1760px;
    padding: 0 149px;
    margin: 120px auto 0;

    @media (max-width: 1366px) {
      padding: 0 60px;
    }

    @media (max-width: 690px) {
      padding: 0 20px;
    }
  }
}
