.wrapper {
  margin-top: 40px;

  .topSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      margin-bottom: 16px;
    }

    .walletInfo {
      display: flex;
      align-items: center;
      gap: 12px;
      font-feature-settings: "zero", "ss01";

      .imgWrapper {
        width: 80px;
        height: 80px;

        @media (max-width: 900px) {
          width: 56px;
          height: 56px;
        }

        img {
          width: 100%;
          object-fit: contain;
          border-radius: 8px;
        }
      }

      .txtInfo {
        display: flex;
        flex-direction: column;

        .label {
          font-weight: 500;
          font-size: 18px;

          @media (max-width: 900px) {
            font-size: 16px;
          }
        }

        .netValue {
          margin-top: 8px;
          font-size: 32px;
          font-weight: bold;

          @media (max-width: 900px) {
            font-size: 20px;
          }
        }

        .address {
          margin-top: 6px;
          font-size: 13px;
          opacity: 0.5;
          font-weight: 500;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .actionBtns {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;

      @media (max-width: 900px) {
        width: 100%;
        justify-content: flex-start;
        gap: 8px;
      }

      .btn {
        display: flex;
        gap: 8px;
        padding: 10px 16px;
        background-color: #222;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        height: 40px;
        border-radius: 6px;
        cursor: pointer;
        line-height: 20px;

        img {
          @media (max-width: 900px) {
            display: none;
          }
        }

        @media (max-width: 900px) {
          font-size: 12px;
          padding: 10px 18px;
        }
      }
    }
  }

  .tabContent {
    .defiSection {
      margin-top: 28px;

      @media (max-width: 900px) {
        margin-top: 16px;
      }
    }

    .ordersSection {
      margin-top: 28px;
      text-align: center;
      font-size: 16px;
      opacity: 0.4;
    }
  }

  .onlyDesktop {
    display: flex;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .onlyMobile {
    display: flex;

    @media (min-width: 600px) {
      display: none;
    }
  }
}
