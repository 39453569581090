.wrapper {
  width: 680px;
  margin: 64px auto;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0 10px 20px #f1f1f1;
  padding: 32px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 900px) {
    width: 100%;
    margin: 16px auto;
    padding: 32px 16px;
  }

  h4 {
    font-size: 21px;
    margin-top: 12px;
    @media (max-width: 900px) {
      font-size: 18px;
    }
  }

  h6 {
    font-size: 15px;
    font-weight: 500;
    background-color: #fabdbd;
    padding: 16px 32px;
    width: 100%;
    margin-bottom: 4px;
    color: red;
    border-radius: 4px;
    @media (max-width: 900px) {
      font-size: 14px;
      padding: 12px 16px;
      line-height: 20px;
    }
  }

  p {
    background-color: #fff6f6;
    padding: 16px 32px;
    font-size: 14px;
    line-height: 24px;
    opacity: 0.5;
    margin: 20px 0 20px 0;
    border-radius: 4px;

    @media (max-width: 900px) {
      padding: 12px 16px;
    }
  }

  a {
    margin-top: 12px;
    opacity: 0.5;
    font-weight: 500;
    text-decoration: underline;
  }
}
