.wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;

  .buyForm {
    margin: 0 0 32px 0;

    @media (max-width: 900px) {
      margin: 8px 0 32px 0;
    }

    h4 {
      margin-top: 20px;
      font-size: 21px;
      font-weight: 500;
      padding: 0 32px;
      margin-bottom: 8px;

      @media (max-width: 900px) {
        font-size: 16px;
        padding: 0 16px;
      }
    }

    .becomeOwner {
      font-size: 14px;
      padding: 16px 32px;
      margin-bottom: 8px;
      line-height: 22px;
      background-color: #222;
      font-weight: 400;
      color: #fff;
      text-align: center;

      @media (max-width: 900px) {
        padding: 16px 16px;
        font-size: 13px;
      }
    }

    .description {
      font-size: 13px;
      opacity: 0.5;
      padding: 0 32px;
      line-height: 16px;

      @media (max-width: 900px) {
        padding: 0 16px;
      }
    }

    .priceBanner {
      background-color: #fff6f6;
      padding: 20px 0;
      display: flex;

      @media (max-width: 680px) {
        margin-top: 8px;
        flex-direction: column;
        gap: 16px;
      }

      .price {
        padding: 0 32px;
        font-weight: 600;
        letter-spacing: 0.3px;
        font-size: 14px;
        margin-bottom: 2px;

        @media (max-width: 900px) {
          padding: 0 16px;
        }
      }
    }

    .formWrapper {
      .customInputWrapper {
        padding: 0 32px;
        margin-top: 28px;

        @media (max-width: 900px) {
          padding: 0 16px;
        }

        .label {
          font-size: 16px;
          font-weight: 500;
        }

        .actionLabel {
          margin-top: 6px;
          font-size: 13px;
          opacity: 0.4;

          .maxBalance {
            text-decoration: underline;
            font-weight: 600;
            cursor: pointer;
          }

          .availableTokens {
            text-decoration: underline;
            font-weight: 600;
            cursor: pointer;
          }
        }

        .customInput {
          margin-top: 8px;
          height: 48px;
          width: 100%;
          border: 1px solid #cdcdcd;
          background-color: #fefefe;
          border-radius: 6px;
          padding: 0 12px;
          font-size: 18px;
        }
      }

      .customCheckboxWrapper {
        padding: 20px 32px 0 32px;
        @media (max-width: 900px) {
          padding: 20px 16px 0 16px;
        }
        .labelWrapper {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 14px;
          line-height: 17px;
          font-weight: 500;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          border-radius: 4px;
          @media (max-width: 900px) {
            font-size: 12px;
          }

          .termsLink {
            font-weight: 500;
            text-decoration: underline;
            color: #000;
          }

          .checkbox {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            border-radius: 4px;

            &:checked {
              ~ .checkmark {
                background-color: #222;
                border-radius: 4px;

                &:after {
                  display: block;
                }
              }
            }
          }

          &:hover {
            input {
              ~ .checkmark {
                background-color: #ccc;
              }
            }
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
            border-radius: 4px;

            &:after {
              content: "";
              position: absolute;
              display: none;
              left: 9px;
              top: 5px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
      }

      .formError {
        display: flex;
        margin-top: 12px;
        padding: 16px 32px 16px 32px;
        background-color: #fbcece;
        color: red;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        align-items: center;
        justify-content: center;

        @media (max-width: 900px) {
          margin-top: 8px;
          font-size: 12px;
          line-height: 16px;
          padding: 16px;
        }
      }

      .actionBtnWrapper {
        // margin-top: 32px;
        padding: 12px 32px 0 32px;

        @media (max-width: 900px) {
          padding: 28px 16px 0 16px;
        }

        .btn {
          background: linear-gradient(0deg, rgb(255, 56, 92) 35%, rgb(255, 122, 146) 100%);
          width: 100%;
          border: none;
          color: #fff;
          padding: 16px 0;
          text-align: center;
          border-radius: 6px;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 0.3px;
          cursor: pointer;

          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }

      .feeInfo {
        margin-top: 8px;
        padding: 0 32px;
        text-align: center;
        opacity: 0.5;
      }
    }
  }
}
