.transactionsSection {
  margin-top: 28px;
  background-color: #fffafa;
  border: 1px solid #dddddd;

  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px #dddddd72;
  overflow: hidden;

  @media (max-width: 900px) {
    margin-top: 16px;
  }

  .transactionSection {
    padding: 24px;

    &:not(:first-child) {
      border-top: 1px solid #ddd;
    }

    @media (max-width: 900px) {
      padding: 20px;
    }

    .date {
      font-size: 14px;
      font-weight: 600;
      opacity: 0.4;
      margin-bottom: 8px;
    }
  }
}