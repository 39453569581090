.wrapper {
  margin: 140px auto;
  width: 560px;
  padding: 48px 40px 36px 40px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 690px) {
    margin: 60px auto;
    width: 100%;
  }

  .selector {
    border-radius: 6px;
    border: 1px solid #eaeaea;
    padding: 16px;
    margin-bottom: 12px;
    opacity: 0.6;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &.active {
      opacity: 1;
      border: 1px solid #ff385c;
      background-color: #fff5f7;
    }

    .selectorTitle {
      font-weight: 500;
      font-size: 16px;
    }
    .selectorDesc {
      margin-top: 4px;
      opacity: 0.5;
      line-height: 16px;
      font-size: 13px;
    }
  }

  .linkSwitcher {
    font-size: 12px;
    text-align: center;
    text-decoration: underline;
    color: #222;
    margin-top: 12px;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  h4 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 8px;
  }

  .description {
    font-size: 16px;
    text-align: center;
    margin-bottom: 32px;
    opacity: 0.4;
    line-height: 24px;
  }

  .formWrapper {
    width: 100%;

    .inputWrapper {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;

      label {
        font-size: 12px;
        opacity: 0.4;
        margin-bottom: 2px;
      }

      .formInput {
        padding: 12px 16px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #ddd;
      }
    }

    .errorMessage {
      color: rgb(245, 69, 69);
      font-weight: 500;
    }

    .buttonForm {
      margin-top: 12px;
      width: 100%;
      height: 44px;
      background: linear-gradient(0deg, rgb(255, 56, 92) 35%, rgb(255, 122, 146) 100%);
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
