.wrapper {
  border-top: 1px solid rgb(198, 220, 235);
  box-shadow: 0px -2px 5px rgba(164, 150, 150, 0.3);
  background-color: #FFFFFF;
  margin-top: 128px;

  .content {
    margin-top: 32px;
    padding: 25px 200px 20px 200px;
    font-size: 15px;
    line-height: 24px;
    color: #000000;

      @media (max-width: 1366px) {
          padding: 0 60px;
      }

      .hr {
        border-top-color: #bbb;
        opacity: 0.5;
      }

      .hr.small {
        margin: 20px 0
      }

      .h6 {
        color: #89154b;
        font-size: 16px;
        text-transform: uppercase;
        margin-top: 5px;
        margin-bottom: 5px;
        letter-spacing: 2px;
        font-weight: bold;
      }

      .a {
        color: #737373;
        font-weight: 600;
      }

      .a:hover {
        color: #3366cc;
        text-decoration: none;
      }

  }

  .footerLinks {
    padding-left: 0;
    list-style: none;

    .li {
        display: block
    }

    .a {
      color: #000000
    }
  }

  .footerLinks a:active,
  .footerLinks a:focus,
  .footerLinks a:hover {
    color: #3366cc;
    text-decoration: none;
  }

  .footerLinks.inline li {
    display: inline-block
  }

  .iconWrapper {
    img {
      width: 50%;
      object-fit: contain;
    }
  }

  .content .socialIcons {
    text-align: right
  }

  .content .socialIcons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: 6px;
    margin-right: 0;
    border-radius: 100%;
    background-color: #33353d
  }

  .copyrightText {
    margin: 0
  }

  @media (max-width:991px) {
    .content [class^=col-] {
      margin-bottom: 30px
    }
  }

  @media (max-width:767px) {
    .content {
      padding-bottom: 0
    }

    .content .copyrightText,
    .content .socialIcons {
      text-align: center
    }
  }

  .socialIcons {
    padding-left: 0;
    margin: 0;
    list-style: none
  }
  
  .socialIcons li {
    display: inline-block;
    margin-bottom: 4px
  }

  .socialIcons li.title {
    margin-right: 15px;
    text-transform: uppercase;
    color: #96a2b2;
    font-weight: 700;
    font-size: 13px
  }

  .socialIcons a {
    background-color: #eceeef;
    color: #818a91;
    font-size: 16px;
    display: inline-block;
    line-height: 44px;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear
  }
  
  .socialIcons a:active,
  .socialIcons a:focus,
  .socialIcons a:hover {
    color: #fff;
    background-color: #29aafe
  }

  .socialIcons.size-sm a {
    line-height: 34px;
    height: 34px;
    width: 34px;
    font-size: 14px
  }

  .socialIcons a.facebook:hover {
    background-color: #3b5998
  }

  .socialIcons a.twitter:hover {
    background-color: #00aced
  }

  .socialIcons a.linkedin:hover {
    background-color: #007bb6
  }

  .socialIcons a.dribbble:hover {
    background-color: #ea4c89
  }

  @media (max-width:767px) {
    .socialIcons li.title {
      display: block;
      margin-right: 0;
      font-weight: 600
    }
  }
}
