.errorBanner {
  // margin-top: 8px;
  padding: 16px 32px;
  background-color: #fbcece;
  color: red;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;

  @media (max-width: 900px) {
    padding: 16px 16px;
  }

  .labelError {
    font-size: 16px;

    @media (max-width: 900px) {
      font-size: 14px;
    }
  }

  .descError {
    margin-top: 6px;
    font-size: 13px;
    opacity: 0.6;
    line-height: 18px;

    @media (max-width: 900px) {
      font-size: 12px;
    }
  }
}
