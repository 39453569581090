.customInputWrapper {
  padding: 0 32px;
  margin-top: 28px;
  @media (max-width: 900px) {
    padding: 0 16px;
  }
  .label {
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 900px) {
      font-size: 14px;
    }
  }

  .actionLabel {
    margin-top: 6px;
    font-size: 13px;
    color: #959595;
    .maxBalance {
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        color: #4b4b4b;
      }
    }
    .availableTokens {
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        color: #4b4b4b;
      }
    }
  }

  .customInput {
    margin-top: 8px;
    height: 48px;
    width: 100%;
    border: 1px solid #cdcdcd;
    background-color: #fefefe;
    border-radius: 6px;
    padding: 0 12px;
    font-size: 18px;
  }
}
