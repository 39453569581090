.wrapper {
  width: 50%;
  display: flex;
  gap: 80px;
  margin: 0 auto;
  padding: 0 0 120px 0;

  @media (max-width: 690px) {
    width: 100%;
  }

  .commonForm {
    width: 100%;

    .mainSection {
      .formSection {
        margin-top: 18px;

        .uploadThumbnailInput {
          display: flex;
          width: 100%;
          height: 160px;
          justify-content: center;
          align-items: center;
          background-color: #f0f0f0;
          border-radius: 4px;
          color: #7c7c7c;
          cursor: pointer;
        }

        .uploadedImages {
          display: flex;
          width: 100%;
          height: 160px;
          justify-content: center;
          align-items: center;
          background-color: #f0f0f0;
          border-radius: 4px;
          color: #7c7c7c;
          cursor: pointer;
          gap: 32px;

          .uploadedImage {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            gap: 8px;
          }
        }

        .uploadedDocuments {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 160px;
          justify-content: center;
          align-items: center;
          background-color: #f0f0f0;
          border-radius: 4px;
          color: #7c7c7c;
          cursor: pointer;
          gap: 28px;

          .uploadedDocument {
            display: flex;
            font-size: 18px;
            justify-content: center;
            align-items: center;
            gap: 8px;
          }

          .removeDoc {
            font-size: 12px;
            text-transform: uppercase;
            opacity: 0.7;
          }
        }

        .imgUploaded {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .removeBtn {
          cursor: pointer;
          text-transform: uppercase;
          font-weight: 500;
          color: #f66b6b;
          letter-spacing: 0.4px;
          text-align: center;
          margin-top: 8px;

          &:hover {
            text-decoration: underline;
          }
        }

        .inputWrapper {
          margin-bottom: 16px;
          display: flex;
          flex-direction: column;

          label {
            font-size: 12px;
            opacity: 0.4;
            margin-bottom: 2px;
          }

          .formInput {
            padding: 12px 16px;
            border-radius: 4px;
            font-size: 16px;
            border: 1px solid #ddd;
          }

          .hintExample {
            font-size: 13px;
            opacity: 0.4;
            margin-top: 4px;
          }
        }

        .errorWrapper {
          background-color: rgba(239, 122, 122, 0.261);
          color: #e71212;
          padding: 16px 8px;
          border-radius: 4px;
        }

        .selector {
          border-radius: 6px;
          border: 1px solid #eaeaea;
          padding: 16px;
          margin-bottom: 12px;
          opacity: 0.8;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }

          &.active {
            opacity: 1;
            border: 1px solid #222;
            background-color: #fff;
          }

          .selectorTitle {
            font-weight: 500;
            font-size: 16px;
          }
          .selectorDesc {
            margin-top: 4px;
            color: #22222285;
            line-height: 16px;
            font-size: 13px;

            a {
              color: #222;
              font-weight: 500;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .separator {
      margin: 40px 0 32px 0;
    }

    .submitBtn {
      margin-top: 32px;
      width: 50%;
      color: #ffffff;
      height: 52px;
      background: linear-gradient(0deg, rgb(255, 56, 92) 35%, rgb(255, 122, 146) 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      letter-spacing: 0.6px;
      font-size: 16px;
      border-radius: 6px;
      cursor: pointer;
      border: none;

      @media (max-width: 900px) {
        width: 100%;
      }
    }
  }

  .additional {
    width: 340px;
    height: auto;

    .helpSection {
      padding: 20px;
      border: 1px solid #d6d4d4;
      border-radius: 6px;
      margin-bottom: 20px;

      .title {
        margin-top: 12px;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        line-height: 24px;
      }

      .desc {
        font-size: 14px;
        opacity: 0.4;
        line-height: 20px;
      }
    }
  }

  .header {
    font-size: 20px;
    font-weight: 500;
  }

  .description {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.3;
    line-height: 21px;
  }
}

input[type='radio'] {
  margin: 16px; /* Space between button and label */
  appearance: radio; /* Ensures radio appearance if overridden */
  width: 16px; /* Default size */
  height: 16px;
}

.refinanceLabel {
  font-weight: bold;
}