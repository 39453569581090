.connectWallet {
  background: linear-gradient(0deg, rgb(255, 56, 92) 35%, rgb(255, 122, 146) 100%);
  color: #fff;
  padding: 16px 16px;
  text-align: center;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3px;
  cursor: pointer;
  width: 100%;
}

.balanceWrapper {
  box-shadow: 0px 2px 4px 0px #dddddd;
  border: 1px solid #ffffff;
  border-radius: 8px;
  display: flex;
  padding: 4px;
  height: 44px;
  gap: 24px;
  cursor: pointer;

  &:hover {
    box-shadow: none;
    border: 1px solid #dddddd51;
  }

  .balanceInfo {
    margin-top: 2px;
    margin-left: 14px;

    .title {
      font-weight: 800;
      font-size: 9px;
      text-transform: uppercase;
      letter-spacing: 2px;
      opacity: 0.3;
      margin-bottom: 6px;
    }

    .amount {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .buyBtn {
    background-color: #ff385c;
    border-radius: 8px;
  }
}

.walletModalWrapper {
  width: 100px;
}
