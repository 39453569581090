.wrapper {
  font-size: 12px;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 900px) {
    padding: 0;
  }

  .iconWrapper {
    width: 45px;
    height: 45px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .statusInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .label {
      font-size: 16px;
      font-weight: 400;
    }
    .date {
      font-size: 14px;
      font-weight: 500;
      opacity: 0.5;
    }
    .type {
      font-size: 8px;
      border: 1px solid #878787;
      padding: 4px;
      color: #fff;
      background-color: #222;
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      border-radius: 8px;
    }
  }
}
