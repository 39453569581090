.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .iconWrapper {
  }

  .title {
    font-size: 21px;
    margin-top: 24px;
  }

  .description {
    margin-top: 8px;
    width: 400px;
    text-align: center;
    line-height: 20px;
    opacity: 0.5;

    @media (max-width: 900px) {
      width: 90%;
    }
  }

  .link {
    margin-top: 24px;
    font-size: 20px;
    text-decoration: underline;
  }
}
