.actionBtns {
  width: 100%;
  .start {
    background: linear-gradient(0deg, rgb(255, 56, 92) 35%, rgb(255, 122, 146) 100%);
    color: #fff;
    padding: 16px 16px;
    text-align: center;
    border-radius: 6px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.3px;
    cursor: pointer;
    width: 100%;
    border: none;
  }

  .skip {
    margin-top: 15px;
    background-color: #fff;
    border: none;
    outline: none;
    text-decoration: underline;
    cursor: pointer;
  }
}
