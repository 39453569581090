.wrapper {
  margin-top: 28px;
  padding: 40px;
  background-color: #fff;

  @media (max-width: 900px) {
    padding: 0;
    background-color: #fffafa;
    margin-top: 16px;
  }
  .propertyInfo {
    padding: 16px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .leftSection {
      display: flex;
      align-items: center;
      gap: 12px;
      .thumbWrapper {
        width: 92px;
        height: 92px;
        @media (max-width: 900px) {
          width: 72px;
          height: 72px;
        }
        img {
          border-radius: 6px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .tokensaleInfo {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .idTxt {
          font-size: 12px;
          opacity: 0.5;
          font-weight: 500;
        }
        .titleTxt {
          font-size: 18px;
          font-weight: 500;
          @media (max-width: 900px) {
            font-size: 17px;
          }
        }
        .adddressTxt {
          font-size: 14px;
          @media (max-width: 900px) {
            font-size: 13px;
          }
        }
      }
    }
    .rightSection {
      display: flex;
      flex-direction: column;
      width: 260px;
      gap: 12px;

      .errorLabel {
        font-size: 9px;
        padding: 4px;
        background-color: #e2a3a3;
        border-radius: 4px;
      }
      @media (max-width: 900px) {
        margin-top: 16px;
        width: 100%;
        gap: 8px;
        flex-direction: column-reverse;
      }
      .rentAmount {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        .description {
          font-size: 12px;
          opacity: 0.5;
        }
      }
      .payBtn {
        background: linear-gradient(0deg, rgb(255, 56, 92) 35%, rgb(255, 122, 146) 100%);
        color: #fff;
        padding: 16px 16px;
        text-align: center;
        border-radius: 6px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.3px;
        cursor: pointer;
        width: 100%;
        border: none;
        text-decoration: none !important;
        &:disabled {
          cursor: progress;
          opacity: 0.3;
        }
      }
    }
  }
  .detailsSection {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    gap: 40px;
    @media (max-width: 900px) {
      flex-direction: column-reverse;
      gap: 8px;
    }
    .transactionsSection {
      width: 100%;
      gap: 16px;
      display: flex;
      flex-direction: column;
      @media (max-width: 900px) {
        gap: 8px;
      }
    }
    .sidebar {
      display: flex;
      flex-direction: column;
      gap: 12px;
      min-width: 280px;
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 24px 16px;
      gap: 20px;

      .statsItem {
        .statsTitle {
          font-size: 13px;
          opacity: 0.5;
        }
        .statsValue {
          margin-top: 6px;
          font-weight: 500;
          font-size: 16px;
          a {
            display: inline-block;
            font-weight: 500;
            font-size: 16px;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .transactionsSection {
    margin-top: 28px;

    @media (max-width: 900px) {
      margin-top: 12px;
    }
    .noneTransactions {
      text-align: center;
      font-size: 16px;
      opacity: 0.3;
    }
  }
}
