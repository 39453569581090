.wrapper {
  position: relative;
  width: 100%;
}

.header {
  padding: 12px;
  border: 1px solid #ededed;
  cursor: pointer;
  border-radius: 6px;
  min-height: 74px;

  &:hover {
    background-color: #fbfbfb;
  }

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    opacity: 0.5;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 13px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.itemsDropDown {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ededed;
  z-index: 9;
  max-height: 250px;
  overflow-y: auto;
  top: 70px;
}

.dropDownWrapper {
  padding: 12px;
  cursor: pointer;

  &:hover {
    background-color: #fbfbfb;
  }
}

.selectedItem {
  display: flex;
  gap: 12px;
  align-items: center;

  .imgWrapper {
    width: 48px;
    height: 48px;

    img {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    text-align: left;

    .infoTitle {
      font-size: 15px;
      font-weight: 500;
    }

    .infoAddress {
      margin-top: 4px;
      font-size: 12px;
      opacity: 0.5;
    }

    .infoContract {
      margin-top: 4px;
      font-size: 9px;
      opacity: 0.5;
    }
  }
}

.selectedItem {
  span {
    vertical-align: middle;
  }
}