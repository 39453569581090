.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  transition:
    opacity 0.3s,
    visibility 0.3s;

  @media (max-width: 900px) {
    padding: 0;
  }
}

.active {
  opacity: 1;
  visibility: visible;
}

.inactive {
  opacity: 0;
  visibility: hidden;
}

.modal {
  background: white;
  // width: 780px;
  max-height: calc(100% - 80px);
  overflow-y: auto;
  // padding: 20px;
  transition: transform 0.3s;
  transform: translateY(-20px);
  border-radius: 12px;

  @media (max-width: 900px) {
    width: 100% !important;
    max-height: 100%;
  }
}

.header {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;

  .closeButton {
    position: absolute;

    left: 20px;
    cursor: pointer;

    img {
      width: 10px;
      height: 10px;
    }
  }

  .headerTitle {
    text-align: center;
    font-size: 16px;
  }
}

.active .modal {
  transform: translateY(0);
}
