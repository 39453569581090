.wrapper {
  margin-top: 32px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px #eeebeb;
  padding: 12px;

  .header {
    background-color: #faf5f5;
    padding: 22px 24px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
  }

  .content {
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }

    .value {
      font-weight: 500;
    }
  }
}
