.wrapper {
  margin-top: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 956px) {
    margin-top: 100px;
  }
  .hero {
    width: 100%;
    margin: 0 auto;
    .heroContent {
      display: flex;
      gap: 60px;
      justify-content: center;
      align-items: center;
      @media (max-width: 956px) {
        flex-direction: column;
        gap: 40px;
      }
      .textWrapper {
        width: calc(50% - 60px);
        display: flex;
        flex-direction: column;
        gap: 32px;
        @media (max-width: 956px) {
          width: 100%;
          gap: 20px;
        }
        h1 {
          font-size: 65px;
          font-weight: 500;
          line-height: 80px;

          @media (max-width: 1620px) {
            font-size: 60px;
            ine-height: 70px;
          }

          @media (max-width: 1520px) {
            font-size: 40px;
            line-height: 50px;
          }

          @media (max-width: 1024px) {
            font-size: 32px;
            line-height: 40px;
          }
        }
        .description {
          font-size: 24px;
          line-height: 32px;

          @media (max-width: 1620px) {
            font-size: 20px;
          }
        }
        .btnsWrapper {
          display: flex;
          gap: 24px;

          @media (max-width: 956px) {
            flex-direction: column;
            gap: 0;
          }
          .ctaBtn {
            padding: 16px 24px;
            text-align: center;
            background: linear-gradient(0deg, rgb(255, 56, 92) 35%, rgb(255, 122, 146) 100%);
            min-width: 180px;
            font-size: 16px;
            color: #fff;
            font-weight: 500;
            letter-spacing: 0.3px;
            border-radius: 4px;
          }
          .linkBtn {
            padding: 16px 0;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.3px;
            border-radius: 4px;
            text-decoration: none;
          }
        }
      }
      .videoWrapper {
        display: flex;
        border-radius: 7px;

        @media (max-width: 956px) {
          padding: 24px;
          border-radius: 18px;
        }

        .video {
          .video-responsive {
              overflow: hidden;
              padding-bottom: 56.25%;
              position: relative;
              height: 0;
          }
          .video-responsive iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            border-radius: 7px;
          }
          }
      }
      .imgWrapper {
        width: calc(40% - 60px);

        @media (max-width: 956px) {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
    }
  }

  .section {
    max-width: 1120px;
    margin-top: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 956px) {
      width: 100%;
      flex-direction: column;
      margin-top: 80px;
      gap: 24px;
    }

    .sectionImgWrapper {
      width: 510px;
      height: 510px;

      @media (max-width: 956px) {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .sectionTxtWrapper {
      width: 50%;

      @media (max-width: 956px) {
        width: 100%;
      }

      .txtHeader {
        font-size: 36px;
        line-height: 45px;
        font-weight: 500;
      }

      .txtContent {
        margin-top: 20px;
        font-size: 17px;
        opacity: 0.5;
        line-height: 24px;
      }
    }

    .fullWidth {
      background-color: #dde3fa;
      padding: 60px;
      border-radius: 24px;
      text-align: center;
      color: #0d2c61;

      @media (max-width: 956px) {
        padding: 24px;
        border-radius: 18px;
      }

      .fullHeader {
        font-size: 40px;
        font-weight: 500;

        @media (max-width: 956px) {
          font-size: 24px;
        }
      }

      .fullText {
        margin-top: 20px;
        font-size: 20px;
        opacity: 0.6;
        line-height: 24px;

        @media (max-width: 956px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}
