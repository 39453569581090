.wrapper {
  margin-top: 40px;
  font-weight: 500;
  font-size: 44px;
  text-align: center;

  h1 {
    font-size: 32px;
    font-weight: normal;
    @media (max-width: 900px) {
      font-size: 32px;
    }

    @media (max-width: 690px) {
      font-size: 28px;
    }
  }

  .countDesc {
    font-size: 18px;
    font-weight: 500;
    opacity: 0.4;
    margin-top: 12px;
    margin-bottom: 12px;

    @media (max-width: 900px) {
      font-size: 18px;
    }
  }

  .tokenSaleList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 28px;
    margin-top: 28px;
    width: 100%;

    @media (max-width: 900px) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }

  .emptyMessage {
    margin-top: 40px;
    font-size: 14px;
    opacity: 0.5;
  }
}
