.wrapper {
  .tabTitleList {
    display: inline-flex;
    background-color: #faf5f5;
    border: 1px solid #dddddd;
    border-radius: 5px;
    align-items: flex-start;
    white-space: nowrap;
    padding: 2px;

    @media (max-width: 690px) {
      // width: 100%;
      white-space: unset;
    }

    .tabTitle {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      opacity: 0.4;
      padding: 12px 48px;
      cursor: pointer;

      @media (max-width: 1130px) {
        padding: 8px 20px;
        font-size: 10px;
      }
    }

    .activeTab {
      opacity: 1;
      background-color: #ffffff;
      border-radius: 5px;
    }
  }

  .mobileDropdown {
    display: none; // По умолчанию скрыт
    position: relative;

    .mobileDropdownHeader {
      padding: 12px 48px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      background-color: #ffffff;
      border: 1px solid #dddddd;
      cursor: pointer;
      border-radius: 6px;

      @media (max-width: 1130px) {
        padding: 12px 20px;
        font-size: 12px;
      }
    }

    .mobileDropdownList {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #faf5f5;
      border: 1px solid #dddddd;
      border-top: none;
      z-index: 9;

      .mobileDropdownItem {
        padding: 12px 48px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        cursor: pointer;
        opacity: 0.4;

        &:hover,
        &.active {
          opacity: 1;
          background-color: #ffffff;
        }

        @media (max-width: 1130px) {
          padding: 12px 20px;
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 1130px) {
    .tabTitleList {
      display: none; // Скрыть список вкладок на мобильных устройствах
    }

    .mobileDropdown {
      display: block; // Показать выпадающий список на мобильных устройствах
    }
  }
}