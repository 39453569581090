.wrapper {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  // margin-top: 40px;

  @media (max-width: 600px) {
    margin-top: 20px;
  }

  .musharakahBanner {
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    padding: 24px;
    background-color: #f7f7f7;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    a {
      text-decoration: underline;
      font-weight: 600;
    }
  }

  .breadcrumb {
    display: flex;
    font-weight: 500;
    font-size: 12px;
    gap: 8px;

    @media (max-width: 600px) {
      font-size: 9px;
      margin-bottom: 12px;
    }

    .disabledLink {
      opacity: 0.3;
    }

    margin-bottom: 32px;
  }

  .location {
    margin-top: 8px;
    display: flex;
    font-weight: 500;
    font-size: 13px;
    align-items: center;
    gap: 4px;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      margin-bottom: 12px;
    }
  }

  .mainInfo {
    width: 100%;
    display: flex;
    gap: 60px;
    padding: 0 12px 0 4px;

    .leftSection {
      margin-top: 40px;
      flex: 1;
      min-width: 0;

      @media (max-width: 600px) {
        margin-top: 20px;
      }

      .infoIcons {
        margin-top: 40px;
        display: flex;
        gap: 40px;
        padding-bottom: 32px;
        border-bottom: 1px solid #dddddd;

        @media (max-width: 1200px) {
          gap: 12px;
        }

        @media (max-width: 600px) {
          padding-bottom: 16px;
        }

        .infoIcon {
          width: 104px;
          height: 104px;
          border: 1px solid #dddddd;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 12px;
          font-weight: 600;
          font-size: 12px;
          text-transform: capitalize;

          img {
            @media (max-width: 600px) {
              width: 20px;
              height: 20px;
            }
          }

          @media (max-width: 600px) {
            width: 60px;
            height: 60px;
            font-size: 9px;
            text-align: center;
          }
        }
      }

      .aboutInfo {
        padding: 32px 0;
        border-bottom: 1px solid #dddddd;

        .title {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .text {
          font-size: 16px;
          opacity: 0.8;
          line-height: 26px;
        }
      }

      .documentsSection {
        margin-top: 40px;

        .documentWrapper {
          margin-top: 16px;
          color: #222;
          padding: 20px;
          border: 1px solid #dddddd;
          border-radius: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .title {
            display: flex;
            gap: 8px;
            align-items: center;
          }
        }
      }
    }

    .rightSection {
      margin-top: 40px;
      width: 380px;

      @media (max-width: 1200px) {
        width: 280px;
      }

      @media (max-width: 900px) {
        display: none;
      }

      .scInfo {
        display: flex;
        padding: 20px;
        align-items: center;
        border: 1px solid #ddd;
        border-radius: 10px;
        margin-bottom: 12px;
        gap: 8px;
        .scIconWrapper {
          width: 32px;
          opacity: 0.5;
          img {
            width: 100%;
          }
        }

        .scDesc {
          font-size: 11px;
          font-weight: 600;
          opacity: 0.8;
          text-decoration: underline;
        }
      }

      .becomeOwnerBtn {
        margin-top: 16px;
        padding: 24px;
        text-align: center;
        background-color: #fff;
        box-shadow: 0px 4px 8px 0px rgba(221, 221, 221, 0.4470588235);
        border-radius: 10px;
        font-weight: 600;
        font-weight: 16px;
        letter-spacing: 0.5px;
        cursor: pointer;
        opacity: 0.9;
        &:hover {
          box-shadow: none;
          opacity: 1;
        }
      }

      .warning {
        padding-bottom: 8px;
        text-align: center;
      }

      .buyTokenModal {
        width: 380px;
        background-color: #fffafa;
        border: 1px solid #ddd;
        border-radius: 10px;
        box-shadow: 0 2px 4px #eeecec;

        @media (max-width: 1200px) {
          width: 280px;
        }

        .tokenPrice {
          text-align: center;
          padding: 20px 0 16px 0;
          border-bottom: 1px solid #dddddd;

          .label {
            font-size: 12px;
            opacity: 0.5;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            margin-bottom: 8px;
          }

          .amount {
            font-size: 15px;
            font-weight: 600;
          }
        }

        .availableTokens {
          text-align: center;
          padding: 16px 0 20px 0;
          color: #ff385c;
          font-size: 14px;
          font-weight: 500;
          opacity: 0.6;
        }

        .buyBtn {
          padding: 20px 0;
          text-align: center;
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
          font-size: 16px;
          background: rgb(255, 56, 92);
          background: linear-gradient(0deg, rgba(255, 56, 92, 1) 35%, rgba(255, 122, 146, 1) 100%);
          border-radius: 0 0 8px 8px;
          cursor: pointer;
        }

        // <div className={styles.rightSection}>
        //   <div className={styles.buyTokenModal}>
        //     <div className={styles.tokenPrice}>
        //       <p className={styles.label}>Token price:</p>
        //       <p className={styles.amount}>{tokenSale.dnrTokenPrice} DNR</p>
        //     </div>
        //     <p className={styles.availableToken}></p>
        //     <div className={styles.buyBtn}>BUY SRX TOKEN</div>
        //   </div>
        // </div>
      }
    }
  }

  .mobileBottom {
    display: none;
    position: fixed;
    left: 0;
    bottom: 0;
    height: auto;
    background-color: #fff;
    width: 100%;

    align-items: center;
    justify-content: space-between;
    padding: 24px 16px;

    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }

    .mtokenPrice {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      .mlabel {
        font-size: 12px;
        padding-bottom: 8px;
      }

      .mamount {
        font-weight: 600;
        font-size: 14px;
      }

      .mavailableTokens {
      }
    }

    .mActionBtns {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 8px;
      .mbuyBtn {
        width: 47%;
        // padding: 20px 32px;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        background: rgb(255, 56, 92);
        background: linear-gradient(0deg, rgba(255, 56, 92, 1) 35%, rgba(255, 122, 146, 1) 100%);
        border-radius: 8px;
      }
      .mBecomeOwnerBtn {
        width: 47%;
        display: flex;
        padding: 20px 0;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        color: #222;
        text-transform: uppercase;
        font-size: 12px;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
      }
    }
  }
}
