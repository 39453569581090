.wrapper {
  background-color: #fffafa;
  border: 1px solid #dddddd;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px #dddddd72;
  cursor: pointer;
  margin-bottom: 12px;
  overflow: hidden;

  @media (max-width: 900px) {
    padding: 20px 16px 20px 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    .assetInfo {
      display: flex;
      gap: 16px;
      align-items: center;
      @media (max-width: 900px) {
        max-width: 60%;
      }
      .imgWrapper {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .txtInfo {
        display: flex;
        flex-direction: column;
        .label {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 4px;
          @media (max-width: 900px) {
            font-size: 14px;
          }
        }

        .symbol {
          font-size: 14px;
          opacity: 0.4;
          @media (max-width: 900px) {
            font-size: 12px;
          }
        }
      }
    }

    .amount {
      display: flex;
      gap: 32px;
      @media (max-width: 900px) {
        gap: 20px;
      }
      .value {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        font-feature-settings: "zero", "ss01";
        @media (max-width: 900px) {
          font-size: 16px;
        }
      }
      .rotate {
        transform: rotate(180deg);
      }
    }
  }

  .content {
    margin: 8px 20px 0;
    padding: 0 20px;
    border-left: 1px solid #ddd;

    @media (max-width: 900px) {
      margin: 0px;
      padding: 0;
      border: none;
    }

    .transactions {
      @media (max-width: 900px) {
        padding-bottom: 12px;
      }
    }
  }
}
