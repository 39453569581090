.wrapper {
  width: 588px;
  margin: 64px auto 0;

  @media (max-width: 900px) {
    width: 100%;
    margin: 32px auto 0;
  }

  .backTolink {
    margin-bottom: 16px;

    a {
      text-decoration: underline;
      font-size: 13px;
      opacity: 0.5;
    }
  }

  .buyForm {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    box-shadow: 0 10px 20px #f1f1f1;
    padding: 32px 0;
    position: relative;

    h4 {
      font-size: 21px;
      font-weight: 500;
      padding: 0 32px;
      margin-bottom: 8px;

      @media (max-width: 900px) {
        font-size: 18px;
        padding: 0 16px;
      }
    }

    .description {
      font-size: 13px;
      opacity: 0.5;
      padding: 0 32px;
      line-height: 16px;
      margin-bottom: 8px;

      @media (max-width: 900px) {
        padding: 0 16px;
      }
    }

    .addressBanner {
      margin-top: 20px;
      background-color: #fff6f6;
      padding: 20px 0;

      .address {
        padding: 0 32px;
        font-weight: 600;
        letter-spacing: 0.3px;
        font-size: 16px;
        margin-bottom: 8px;

        @media (max-width: 900px) {
          padding: 0 16px;
          font-size: 11px;
          font-weight: 500;
        }
      }
    }

    .formWrapper {
      .customInputWrapper {
        padding: 0 32px;
        margin-top: 28px;

        @media (max-width: 900px) {
          padding: 0 16px;
        }

        .label {
          font-size: 14px;
          font-weight: 500;
        }

        .actionLabel {
          margin-top: 6px;
          font-size: 12px;
          opacity: 0.4;

          .maxBalance {
            text-decoration: underline;
            font-weight: 600;
            cursor: pointer;
          }

          .availableTokens {
            text-decoration: underline;
            font-weight: 600;
            cursor: pointer;
          }
        }

        .customInput {
          margin-top: 8px;
          height: 48px;
          width: 100%;
          border: 1px solid #cdcdcd;
          background-color: #fefefe;
          border-radius: 6px;
          padding: 0 12px;
          font-size: 18px;
        }
      }

      .actionBtnWrapper {
        margin-top: 32px;
        padding: 0 32px;

        @media (max-width: 900px) {
          padding: 0 16px;
        }

        .btn {
          background: linear-gradient(0deg, rgb(255, 56, 92) 35%, rgb(255, 122, 146) 100%);
          color: #fff;
          padding: 16px 0;
          text-align: center;
          border-radius: 6px;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 0.3px;
          cursor: pointer;

          &.disabled {
            opacity: 0.5;
          }
        }
      }

      .feeInfo {
        margin-top: 8px;
        padding: 0 32px;
        text-align: center;
        opacity: 0.5;
      }
    }
  }
}
