.wrapper {
  padding-bottom: 40px;

  .infoBanner {
    padding: 32px;
    text-align: center;
    background-color: #222;
    color: #fff;
    line-height: 20px;

    @media (max-width: 900px) {
      padding: 16px;
    }
  }

  .formInfo {
    background-color: #f5f5f5;
    padding: 32px;
    text-align: center;

    hr {
      margin-top: 12px;
      margin-bottom: 12px;
      opacity: 0.4;
    }

    @media (max-width: 900px) {
      padding: 16px;
    }

    .priceInfo {
      font-weight: 500;
      font-size: 18px;
    }

    .availableInfo {
      opacity: 0.5;
    }
  }
  .actionBtnWrapper {
    // margin-top: 32px;
    padding: 12px 32px 0 32px;

    @media (max-width: 900px) {
      padding: 28px 16px 0 16px;
    }

    .btn {
      background: linear-gradient(0deg, rgb(255, 56, 92) 35%, rgb(255, 122, 146) 100%);
      width: 100%;
      border: none;
      color: #fff;
      padding: 16px 0;
      text-align: center;
      border-radius: 6px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.3px;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
