.wrapper {
  width: 60%;
  margin: 0 auto;
  margin-top: 10vh;
  text-align: center;

  .message {
    opacity: 0.7;
    font-size: 18px;
    margin-bottom: 24px;
    line-height: 28px;
  }

  a {
    color: #222;
    text-decoration: underline;
    font-size: 16px;
  }
}
