.container {
  height: 100%;
  text-align: center;
  min-height: calc(100vh - 121px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.6875rem;
  margin-top: 6.75rem;
  min-height: 24rem;
  // border: 1px solid #000;

  & h2 {
    color: var(--black, #1c2e51);
    text-align: center;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 146.667%;
    letter-spacing: -0.6px;
    max-width: 37.5rem;
    margin-bottom: 1.5rem;
  }

  & label {
    display: flex;
    width: 22.5rem;
    padding: 1rem;
    padding-left: 2.5rem;
    align-items: center;
    cursor: pointer;
    position: relative;

    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
  }

  & label:hover {
    border: 1px solid #ff708a;
  }

  & label span::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.625rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.625rem;
    border-radius: 50%;
    border: 1px solid #eaecf0;
    background: #fff;
  }

  & input[type='radio']:checked + span::before {
    border: 5px solid #ff385c;
    box-shadow: 0px 0px 0px 4px #ffdae1;
  }

  & input[type='radio'] {
    display: none;
  }

  & button {
    border: none;
    color: white;
    display: flex;
    width: 22.5rem;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    border-radius: 0.5rem;
    background: linear-gradient(180deg, #ff768e 0%, #ff385c 100%);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.6875rem;
}

.notStarted {
  flex: 1;
  max-width: 46.875rem;
  padding: 4rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.625rem;

  h1 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 146.667%;
    letter-spacing: -0.6px;
    color: var(--black, #1c2e51);
  }

  p {
    color: var(--black, #1c2e51);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    letter-spacing: -0.36px;
  }

  button {
    border: none;
    color: white;
    display: flex;
    width: 300px;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: linear-gradient(180deg, #ff7a92 0%, #ff385c 74.32%);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.success {
  flex: 1;
  max-width: 46.875rem;
  padding: 4rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.625rem;

  h1 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 146.667%;
    letter-spacing: -0.6px;
    color: var(--black, #1c2e51);
  }

  p {
    color: var(--black, #1c2e51);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    letter-spacing: -0.36px;
  }
}

.startAction {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  p {
    color: #989898;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 200% */
  }
}

.logo {
  padding: 8px;
  width: max-content;
  aspect-ratio: 1/1;
  margin: 0 auto;
  border-radius: 40px;
  border: 1px solid #dbe8fb;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  svg {
    width: 36px;
    height: 36px;
  }
}

.button {
  border: none;
  color: white;
  display: flex;
  width: 300px;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(180deg, #ff7a92 0%, #ff385c 74.32%);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 1.25rem;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  margin-top: 2.25rem;

  svg {
    width: 36px;
    height: 36px;
    animation: spin 2s linear infinite;
  }

  span {
    color: #989898;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 200% */
  }
}

.error {
  margin-top: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  color: #ff385c;
  font-size: 18px;
  font-weight: 600;
}

.success {
  margin-top: 2.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  text-align: center;
  color: #00b4a0;
  font-size: 18px;
  font-weight: 600;

  p {
    text-align: center;
    font-size: 14px;
  }

  svg {
    width: 48px;
    height: 48px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.formError {
  color: #ff385c;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 24px;
  font-weight: 500;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  max-width: max-content;
  margin: 8px auto;
}