@import "reset";
@import "fonts";

body {
  font-family: "Inter";
  font-weight: normal;
  font-size: 14px;
  background-color: #fffafa;
  color: #222222;

  a {
    text-decoration: none;
    color: #222;
  }

  h3 {
    font-weight: 500;
    font-size: 26px;
  }
}
