.wrapper {
  h1 {
    margin-top: 40px;
    font-size: 26px;
    font-weight: 500;

    @media (max-width: 900px) {
      line-height: 28px;
    }
  }
  .banner {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 24px;
    gap: 16px;
    background-color: #f7f7f7;
    .textDesc {
      font-size: 14px;
      opacity: 0.6;
      line-height: 20px;

      @media (max-width: 900px) {
        font-size: 12px;
      }
    }
  }
  .mainSection {
    .filters {
      display: flex;
      gap: 20px;
      padding: 20px 0;
      border-top: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;

      @media (max-width: 900px) {
        flex-direction: column;
      }
      .filterInput {
        width: 25%;
        border: none;
        padding: 16px;
        border-radius: 4px;
        background-color: #f0f0f0;
        @media (max-width: 900px) {
          width: 100%;
        }
      }

      .customWrapper {
        width: 25%;
        position: relative;

        @media (max-width: 900px) {
          width: 100%;
        }
        .customFilterInput {
          border: none;
          width: 100%;
          height: 100%;
          padding: 12px;
          border-radius: 4px;
          background-color: #f0f0f0;
        }
        .inputIcon {
          position: absolute;
          right: 12px;
          top: 38%;
          width: 12px;
          opacity: 0.4;
        }
      }
    }
  }
}
