.wrapper {
  max-width: 270px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 #dddddd;
  font-size: 11px;
  text-align: left;
  position: relative;

  @media (max-width: 690px) {
    max-width: 100%;
    width: 100%;
  }

  .adLabel {
    position: absolute;
    top: 142px;
    left: 12px;
    width: 34px;
    height: 34px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #dddddd;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff385c;
    font-weight: bold;
    z-index: 1;
  }

  .favLabel {
    position: absolute;
    top: 142px;
    right: 12px;
    width: 34px;
    height: 34px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #dddddd;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
  }

  .thumbnail {
    width: 270px;
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    overflow: hidden;
    position: relative;

    .musharakahLabel {
      position: absolute;
      z-index: 2;
      bottom: -8px;
      padding: 8px 12px;
      background-color: #fff;
      border-radius: 4px;
      font-size: 9px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 600;
      color: #757575;
    }

    @media (max-width: 690px) {
      width: 100%;
      max-height: 200px;
      height: 200px;
      min-height: 200px;
    }

    .daysLeft {
      position: absolute;
      top: 8px;
      left: 12px;
      padding: 4px 8px;
      background-color: #ffffff;
      font-weight: 600;
      color: #ff385c;
      font-size: 9px;
      text-transform: uppercase;
      border-radius: 10px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px 10px 0 0;
    }
  }

  .name {
    margin-top: 12px;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .address {
    padding: 0 12px;
    margin-top: 4px;
    font-size: 13px;
    color: #b4a5a5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .additionalInfo {
    margin: 16px 0;
    padding: 0 12px;
    text-transform: capitalize;

    .infoItem {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;

      .title {
        font-weight: 500;
        font-size: 11px;
        color: #b4a5a5;
      }

      .amount {
        font-weight: bold;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #ffecf0;
      }
    }
  }

  .available {
    font-weight: 500;
    font-size: 10px;
    color: #ff385c;
    opacity: 0.5;
    text-align: center;
    margin-bottom: 8px;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    // display: block;
    padding: 12px 0;
    font-size: 12px;
    border-radius: 0 0 10px 10px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #fff;
    background: rgb(255, 56, 92);
    background: linear-gradient(0deg, rgba(255, 56, 92, 1) 35%, rgba(255, 122, 146, 1) 100%);
  }
}
