.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 18px;
  width: 100%;
  padding: 20px 24px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  transition: background 0.3s;

  &:hover {
    background: #fff;
  }
}

.proposal {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  gap: 16px;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h3 {
      font-size: 18px;
      font-weight: 600;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #475467;
    }
  }
}

.viewProposal {
  display: flex;
  gap: 8px;
  align-items: center;
  color: #4b4b4b;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: transform 0.3s;

  &[aria-expanded='true'] {
    transform: rotate(180deg);
  }
}

.proposalsTable {
  width: 100%;
  margin-top: 16px;
  border-collapse: collapse;

  th {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #475467;
    padding: 12px 0;
    border-bottom: 1px solid #e5e5e5;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  td {
    font-size: 14px;
    font-weight: 400;
    color: #475467;
    padding: 12px 0;
    border-bottom: 1px solid #e5e5e5;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  td:nth-child(2),
  th:nth-child(2)
   {
    text-align: center;
    white-space: nowrap;
  }

  td:nth-child(3),
  th:nth-child(3) {
    text-align: right;
    white-space: nowrap;
    padding-right: 0;
  }

  tr:last-child td {
    border-bottom: none;
  }

}

.voteButton {
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  color: white;
  display: flex;
  width: 22.5rem;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  border-radius: 0.5rem;
  background: linear-gradient(180deg, #ff768e 0%, #ff385c 100%);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}